#FormContact form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 3em;
    margin: 0 auto;
    width: 80%;
}

#FormContact form div {
    width: 100%;
    font-size: 18px;
    margin-bottom: 2em;
}

#FormContact form div label {
    font-size: 18px;
    font-weight: 500;
}

#FormContact form div input {
    border: 2px solid var(--color-grey);
    outline: none;
    box-shadow: none;
    padding-left: .5em;
    width: 96%;
}

#FormContact form div textarea {
    border: 2px solid var(--color-grey);
    outline: none;
    box-shadow: none;
    height: 200px;
}

#FormContact form input[type="submit"] {
    margin-bottom: 2em;
    border: none;
    background-color: var(--color-dark-green);
    font-size: 18px;
    padding: .5em 1.5em .5em 1.5em;
    color: var(--color-white);
    font-weight: 500;
    border-radius: 7px;
    cursor: pointer;
}

#FormContact form div#privacy-policy {
    color: rgba(0, 0, 0, 0.2);
    font-weight: 500;
    text-align: center;
}

#FormContact form div#privacy-policy a {
    color: rgba(0, 0, 0, 0.2);
}

@media (min-width: 450px) {
    #FormContact form div input {
        width: 97.5%;
    }
}

@media (min-width: 650px) {
    #FormContact form {
        width: 60%;
    }
}

@media (min-width: 900px) {
    #FormContact form {
        width: 40%;
    }
}

@media (min-width: 1250px) {
    #FormContact form div input {
        width: 98.5%;
    }
}

@media (min-width: 2000px) {
    #FormContact form {
        width: 30%;
    }
}

@media (min-width: 2750) {
    #FormContact form div input {
        width: 99%;
    }
}