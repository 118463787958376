.t-map {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blok-map-landing {
    position: absolute;
    background-color: #0000003f;
    width: 100%;
    height: 100%;
}

.button-map-landing {
    position: absolute;
    padding: 10px 10px 10px 20px;
    border: none;
    border-radius: 50% 0px 0px 50%;
    height: 70px;
    font-size: 10px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    right: 0;
    background-color: #194d18 !important;
}

.close {
    display: none;
}

@media (min-width: 760px) {
    .button-map-landing {
        padding: 10px 20px;
        height: auto;
        top: 0;
        right: auto;
        border-radius: 0px 0px 50% 50%;
        width: 100px;
    }
    .button-map-landing > i {
        font-size: 25px !important;
    }
}
