.card-park .card .card-image .btn-floating {
    background-color: var(--color-dark-green);
}

.card-park .card .card-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.card-park .card .card-content {
    height: 150px;
    display: grid;
    place-items: center;
}