#CardService .card-services {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Estilos para el título */
#CardService .card-services .card-header {
    background-color: #F28F16;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
}

/* Estilos para el cuerpo */
#CardService .card-services .card-body {
    padding: 15px;
    text-align: justify;
}

/* Estilos para el pie de página */
#CardService .card-services .card-footer {
    background-color: #f5f5f5;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#CardService.container {
    display: flex;
    flex-direction: column;
}

/* Estilos para las columnas */
#CardService .column {
    text-align: center;
    margin-bottom: 20px;
    flex-basis: calc(50% - 20px);
}

/* Estilos para hacerlo responsivo */
@media (min-width: 768px) {
    #CardService.container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #CardService .column {
        flex-basis: calc(33.33% - 20px);
    }
}
