@import url(https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap);
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-dark-green: #194d18;
  --color-medium-green: #4f5e45;
  --color-light-green: #3bae29;
  --color-orange: #e4942d;
  --color-yellow: #ffd83c;
  --color-crimson: #4e180c;
  --color-grey: #eaeceb;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

.rec-arrow {
  display: none !important;
}

.rec-slider-container {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.rec-dot:hover,
.rec-dot:focus {
  box-shadow: 0 0 1px 3px #4e180c !important;
  box-shadow: 0 0 1px 3px var(--color-crimson) !important;
}

.rec-dot_active {
  background: #4e180c !important;
  background: var(--color-crimson) !important;
  box-shadow: 0 0 1px 3px #4e180c !important;
  box-shadow: 0 0 1px 3px var(--color-crimson) !important;
}

.custom-carousel-item,
.custom-carousel-item img {
  width: 100vw;
}

iframe {
  width: 100%;
  height: 90vh;
  margin: 25px 0;
}
.grecaptcha-badge {
    display:none;
}
nav {
    background: var(--color-dark-green) !important;
    box-shadow: none !important;
    align-content: center;
}

div.form-group div input[placeholder="Search"] {
    height: 61px;
    box-shadow: none;
    padding-left: 1em;
    border: 1.5px solid var(--color-grey);
    width: 93%;
}

div ul#slide-out li a[href="#modalSearch"] {
    position: relative;
}

div ul#slide-out li a[href="#modalSearch"] i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="submit"] {
    background-color: var(--color-dark-green);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
    padding: .5em 1em .5em 1em;
    font-size: 20px;
    cursor: pointer;
}

#modalSearch button#btn-close-modal {
    position:absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: var(--color-white);
    border-bottom-left-radius: 25px;
}

nav .brand-logo img {
    width: 80px;
}

nav ul li a {
    max-height: 62px;
    font-size: 20px;
}

nav .line-white-vertical {
    position: relative;
}

nav .line-white-vertical::after {
    position: absolute;
    content: '';
    right: -10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 3px;
    height: 40px;
    background-color: white;
}

nav ul li a img {
    width: 45px;
    height: 45px;
    object-fit: contain;
    margin-top: 10px;
}

ul.sidenav li {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #f1f1f1;
    padding-top: 1em;
    padding-bottom: 1em;
}

ul.sidenav li a img {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

@media (min-width: 400px) {
    div.form-group div input[placeholder="Search"] {
        width: 94%;
    }
}

@media (min-width: 400px) {
    div.form-group div input[placeholder="Search"] {
        width: 94%;
    }
}

@media (min-width: 992px) {
    div.form-group div input[placeholder="Search"] {
        height: 64px;
    }
}

@media (min-width: 1700px) {
    nav ul li a {
        font-size: 25px;
    }
}

div#Footer footer {
    position: relative;
    background-color: var(--color-dark-green);
    font-size: 20px;
    z-index: 1;
}

div#Footer footer::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/17.50554e5a.jpg);
    background-size: cover;
    background-position: 100%;
    opacity: 15%;
    z-index: -1;
}

div#Footer footer a {
    color: white;
}

div#Footer footer img.logo {
    width: 250px;
    object-fit: contain;
}

div#Footer footer div.container div.row div.col:first-child {
    display: flex;
    justify-content: center;
}

div#Footer footer div.container div.row div.col:last-child > div {
    text-align: center;
    margin-top: 2em;
}

@media (min-width: 992px) {
    div#Footer footer div.container div.row div.col:last-child > div {
        text-align: left;
        margin-top: 0;
    }
}
#Landing div.custom-carousel-container {
    margin: 0 0 100px 0;
}

#Landing div.custom-carousel-container div.carousel-container {
    position: relative;
}

#Landing
    div.custom-carousel-container
    div.carousel-container
    .custom-carousel-item {
    position: relative;
}

#Landing
    div.custom-carousel-container
    div.carousel-container
    .custom-carousel-item::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(/static/media/text-banner-android.056fdf22.png);
    background-size: 100% 98%;
    background-repeat: no-repeat;
}

#Landing
    div.custom-carousel-container
    div.carousel-container
    .custom-carousel-item-first::before {
    background-image: url(/static/media/text-banner-android-icon.46784efd.png) !important;
}

#Landing div.row {
    margin: 0;
}

#Landing div.col {
    padding: 0;
}

#Landing div.collage {
    margin-top: -10em;
}

#Landing div.collage div.top {
    background: var(--color-medium-green);
}

#Landing div.collage div.top div img {
    width: 100%;
}

#Landing div.collage div.top div:last-child {
    display: flex;
    justify-content: center;
    align-content: center;
}

#Landing div.collage div.top div span {
    display: block;
    color: white;
    width: 80%;
    text-align: center;
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
}

#Landing div.collage div.bottom {
    background: var(--color-orange);
}

#Landing div.collage div.bottom div.orange-top-bar {
    height: 3em;
}

#Landing div.collage div.bottom img {
    width: 100%;
}

#Landing div.show-desk,
#Landing div.show-desk-2 {
    display: none !important;
}

#Landing div.collage div.bottom div.bottom-text,
#Landing div.collage div.bottom div.top-text {
    display: flex;
    justify-content: center;
}

#Landing
    div.collage
    div.bottom
    div.left-portion
    div.bottom-text
    div.box-behind-text,
#Landing
    div.collage
    div.bottom
    div.right-portion
    div.bottom-text
    div.box-behind-text {
    width: 250px;
    height: 80px;
    border: 3px solid var(--color-white);
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em;
}

#Landing
    div.collage
    div.bottom
    div.left-portion
    div.bottom-text
    div.box-behind-text
    span.bordered-text,
#Landing
    div.collage
    div.bottom
    div.right-portion
    div.bottom-text
    div.box-behind-text
    span.bordered-text {
    color: var(--color-crimson);
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translate(0, -50%);
    width: 280px;
    background: var(--color-orange);
    text-align: center;
}

#Landing div.collage div.bottom div.center-portion {
    background: var(--color-grey);
}

#Landing div.collage div.bottom div.center-portion img {
    margin-top: 3em;
}

#Landing
    div.collage
    div.bottom
    div.center-portion
    div.top-text
    div.box-behind-text {
    width: 250px;
    height: 80px;
    border: 3px solid var(--color-light-green);
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em;
}

#Landing
    div.collage
    div.bottom
    div.center-portion
    div.top-text
    div.box-behind-text
    span.bordered-text {
    color: var(--color-medium-green);
    background: var(--color-grey);
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translate(0, -50%);
    width: 280px;
    text-align: center;
}

#Landing div.secction-1 > div:last-child div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

#Landing div.secction-1 > div:first-child,
#Landing div.secction-2 div.col > div.row:first-child {
    text-align: center;
    padding: 2em 0.5em 2em 0.5em;
}

#Landing div.secction-1 > div:first-child span,
#Landing div.secction-2 div.col > div.row:first-child span {
    color: var(--color-medium-green);
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
}

#Landing div.secction-1 > div:last-child {
    background-color: var(--color-medium-green);
    text-align: center;
}

#Landing div.secction-1 > div:last-child div span {
    color: var(--color-white);
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

#Landing div.secction-1 > div:last-child div img {
    width: 100%;
}

#Landing div.secction-2 div.col > div.row:last-child {
    background-image: url(/static/media/background-secction-image.f2768ad1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

#Landing div.secction-2 div.col > div.row:last-child div {
    padding: 0 0.5em 0 0.5em;
    text-align: center;
    margin: 1em 0 1em 0;
}

#Landing div.secction-2 div.col > div.row:last-child span {
    color: var(--color-white);
    font-weight: 500;
    font-size: 20px;
}

#Landing div.secction-2 div.col > div.row:last-child p {
    color: var(--color-white);
    font-size: 15px;
    line-height: 18px;
}

#Landing div.secction-3 div.row div.col {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

#Landing div.secction-3 div.row div.col img {
    width: 50%;
    height: 200px;
    object-fit: cover;
}

.map-landing .button-map-landing {
    background-color: #1785fb !important;
}

.close {
    display: none;
}

.map-landing .blok-map-landing {
    height: 95% !important;
}
.informative_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.informative_section h2 {
    font-size: 25px;
    text-align: center;
}
.informative_section ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.informative_section ul li {
    font-size: 20px;
    text-align: center;
    padding: 20px 0px;
}
@media (min-width: 500px) {
    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item::before {
        background-image: url(/static/media/text-banner.e40c7c85.png);
        background-size: 100% 99%;
    }

    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item-first::before {
        background-image: url(/static/media/text-banner-icon.86b69390.png) !important;
    }

    #Landing div.secction-1 > div:last-child div img {
        width: 80%;
    }

    #Landing div.secction-2 div.col > div.row:last-child div:nth-child(2n - 1) {
        text-align: left;
    }

    #Landing div.secction-2 div.col > div.row:last-child div:nth-child(2) {
        text-align: right;
    }

    #Landing div.secction-2 div.col > div.row:last-child {
        padding: 0 4em 0 4em;
    }
}

@media (min-width: 600px) {
    #Landing div.secction-1 > div:last-child div.col:first-child {
        display: flex;
        justify-content: flex-end;
    }

    #Landing div.secction-1 > div:last-child div.col:last-child {
        display: flex;
        justify-content: flex-start;
    }

    #Landing div.show-desk {
        display: flex !important;
    }

    #Landing div.show-android {
        display: none !important;
    }

    #Landing div.secction-1 > div:first-child {
        padding-bottom: 5em;
    }

    #Landing div.secction-1 > div:last-child div.row:first-child {
        margin-top: -8%;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(3) {
        margin-top: -12%;
        margin-bottom: -12%;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(4) {
        margin-bottom: -10%;
    }

    #Landing div.secction-2 div.row:first-child {
        margin-top: 2em;
    }

    #Landing div.secction-3 div.row div.col img {
        width: 45%;
        height: 300px;
    }
}

@media (min-width: 760px) {
    .map-landing .button-map-landing {
        top: 22px;
    }
}

@media (min-width: 992px) {
    #Landing div.show-desk-2 {
        display: flex !important;
    }

    #Landing .show-tablet {
        display: none !important;
    }

    #Landing div.collage div.top {
        display: flex;
        align-items: center;
    }

    #Landing div.collage div.top div span {
        font-size: 25px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text {
        width: 300px;
        height: 100px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text
        span.bordered-text {
        font-size: 20px;
        width: 330px;
        padding-left: 15px;
        padding-right: 15px;
    }

    #Landing div.collage div.bottom {
        margin-top: -5px;
    }

    #Landing div.collage div.bottom div.center-portion img {
        margin-top: 0;
        margin-bottom: 3em;
    }

    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item::before {
        background-size: 100% 99.6%;
    }

    #Landing div.secction-1 > div:first-child span,
    #Landing div.secction-2 div.col > div.row:first-child span {
        font-size: 35px;
    }

    #Landing div.secction-1 > div:last-child div span {
        font-size: 25px;
    }

    #Landing div.secction-2 div.col > div.row:last-child span {
        font-size: 30px;
    }

    #Landing div.secction-2 div.col > div.row:last-child p {
        font-size: 20px;
        line-height: 30px;
    }

    #Landing div.secction-1 > div:last-child div img {
        width: 450px;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(3) {
        margin-top: -10%;
        margin-bottom: -10%;
    }

    #Landing div.secction-3 div.row div.col img {
        height: 400px;
    }

    .informative_section h2 {
        font-size: 35px;
    }

    .informative_section ul li {
        font-size: 30px;
        padding: 20px 0px;
    }
}

@media (min-width: 1500px) {
    #Landing div.secction-1 > div:last-child {
        padding-top: 1%;
        padding-bottom: 2%;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(3) {
        margin-top: -8%;
        margin-bottom: -8%;
    }

    #Landing div.secction-3 div.row div.col img {
        width: 45%;
        height: 500px;
    }
}

@media (min-width: 1700px) {
    #Landing div.secction-1 > div:last-child {
        padding-top: 3%;
        padding-bottom: 4%;
    }

    #Landing div.collage div.top div span {
        font-size: 30px;
    }

    #Landing div.secction-2 div.col > div.row:last-child span {
        font-size: 35px;
    }

    #Landing div.secction-2 div.col > div.row:last-child p {
        font-size: 25px;
    }

    #Landing div.secction-1 > div:first-child span,
    #Landing div.secction-2 div.col > div.row:first-child span {
        font-size: 40px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text {
        width: 320px;
        height: 110px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text
        span.bordered-text {
        font-size: 25px;
        width: 350px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #Landing div.secction-3 div.row div.col img {
        height: 600px;
    }
}

@media (min-width: 2200px) {
    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item::before {
        background-size: 100% 99.7%;
    }

    #Landing div.secction-3 div.row div.col img {
        height: 750px;
    }
}

.t-map {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blok-map-landing {
    position: absolute;
    background-color: #0000003f;
    width: 100%;
    height: 100%;
}

.button-map-landing {
    position: absolute;
    padding: 10px 10px 10px 20px;
    border: none;
    border-radius: 50% 0px 0px 50%;
    height: 70px;
    font-size: 10px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    right: 0;
    background-color: #194d18 !important;
}

.close {
    display: none;
}

@media (min-width: 760px) {
    .button-map-landing {
        padding: 10px 20px;
        height: auto;
        top: 0;
        right: auto;
        border-radius: 0px 0px 50% 50%;
        width: 100px;
    }
    .button-map-landing > i {
        font-size: 25px !important;
    }
}

/* font-family: 'Fredericka the Great', cursive; */

#ViewPark .header-park {
    width: 100%;
    height: 200px;
    position: relative;
}

#ViewPark .header-park img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    -webkit-filter: blur(8px);
            filter: blur(8px);
}

#ViewPark .header-park div {
    background-color: rgba(133, 144, 130, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: var(--color-white);
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

#ViewPark .header-park div h1 {
    font-size: 50px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    font-family: 'Fredericka the Great', cursive;
}

#ViewPark .header-park div p {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

#ViewPark iframe {
    width: 100vw !important;
    height: 300px;
    margin-top: 0;
    margin-bottom: 0;
}

#ViewPark div#secction-1 {
    width: 100%;
    position: relative;
}

#ViewPark div#secction-1 img {
    width: 100%;
    object-fit: cover;
}

#ViewPark div#secction-1 div {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
}

#ViewPark div#secction-1 div span {
    width: 100%;
    color: var(--color-white);
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

#ViewPark div#secction-1 div span:nth-child(2) {
    text-align: right;
}

#ViewPark div#secction-1 div span::before {
    content: '';
    height: 2.5px;
    background-color: var(--color-white);
    position: absolute;
    bottom: 0;
}

#ViewPark div#secction-1 div .span-empty::before {
    background-color: transparent;
}

#ViewPark div#secction-1 div span:first-child::before {
    width: 290px;
}

#ViewPark div#secction-1 div span:last-child::before {
    width: 300px;
}

#ViewPark div#secction-1 div span:last-child::before {
    right: 0;
}

#ViewPark div#secction-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

#ViewPark div#secction-2 div {
    width: 100%;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

#ViewPark div#secction-2 div * {
    font-family: 'Fredericka the Great', cursive;
    color: #194d18 !important;
}

#ViewPark div#secction-2 img {
    width: 30%;
    max-width: 150px;
    margin: 0;
    object-fit: contain;
}

#ViewPark div#secction-3 .sub-secction-3 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

#ViewPark div#secction-3 .image-card {
    width: 100%;
    height: 300px;
}

#ViewPark div#secction-3 .body-card {
    position: relative;
    width: 100%;
    height: 300px;
    color: var(--color-white);
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: bold;
}

#ViewPark div#secction-3 .body-card span {
    position: relative;
    margin-bottom: 1em;
}

#ViewPark div#secction-3 .text-header-card::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0.5em);
    width: 250px;
    height: 2px;
    background-color: var(--color-white);
}

#ViewPark div#secction-3 .body-card span,
#ViewPark div#secction-3 .body-card p {
    width: 100%;
    text-align: center;
}

#ViewPark div#secction-3 .body-card p {
    margin-top: -1em;
}

#ViewPark div#secction-3 .body-card > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

#ViewPark div#secction-3 .body-card > div > img {
    width: 60px;
    height: 60px;
}

#ViewPark div#secction-3 .body-card > div {
    width: 100%;
    align-items: center;
    display: flex;
}

#ViewPark div#secction-3 .sub-card-left {
    text-align: left;
    margin-left: 0.5em;
}

#ViewPark div#secction-3 .sub-card-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#ViewPark div#secction-3 .sub-card-right {
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
}

#ViewPark div#secction-3 .body-card button {
    color: var(--color-white);
    font-size: 15px;
    background-color: var(--color-light-green);
    border: none;
    border-radius: 5px;
    padding: 0.5em 1em 0.5em 1em;
    height: 35px;
}

#ViewPark div#secction-3 .sub-card-center button {
    background-color: transparent;
    border: 2px solid var(--color-light-green);
}

#ViewPark div#secction-3 .button-color-crimson button {
    background-color: var(--color-crimson);
}

#ViewPark div#secction-3 .button-border-color-crimson button {
    border-color: var(--color-crimson);
}

#ViewPark div#secction-4 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}

#ViewPark div#secction-4 img {
    width: 100%;
    height: 250px;
}

#ViewPark div#secction-4 div {
    background-color: #47573d;
    color: var(--color-white);
    width: 100%;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

#ViewPark div#secction-4 div hr {
    width: 90%;
}

#ViewPark div#opportunities {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#ViewPark div#opportunities div.sub-sec5-1 {
    width: 100%;
    position: relative;
}

#ViewPark div#opportunities div.sub-sec5-1 img {
    width: 100%;
    height: 80px;
}

#ViewPark div#opportunities div.sub-sec5-1 span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: 500;
    font-size: 20px;
}

#ViewPark div#opportunities div.sub-sec5-2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    align-content: center;
    background-color: var(--color-grey);
    margin-top: 2em;
    padding-top: 2.5em;
}

#ViewPark div#opportunities div.sub-sec5-2 div {
    width: 100%;
    margin-top: -10px;
}

#ViewPark div#opportunities div.sub-sec5-2 div img {
    width: 100%;
}

#ViewPark div#opportunities div.sub-sec5-2 div div {
    width: 100%;
    background-color: #6c8266;
    margin-top: -5px;
    margin-bottom: 0.5em;
    color: var(--color-white);
    text-align: center;
    font-size: 18px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

#ViewPark div#secction-6 > div:first-child {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

#ViewPark div#secction-6 > div:first-child div {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

#ViewPark div#secction-6 > div:first-child div img {
    width: 100%;
    height: 100px;
}

#ViewPark div#secction-6 > div:first-child span {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #48583e;
    text-transform: uppercase;
}

#ViewPark div#secction-6 > div:first-child span:last-child {
    margin-bottom: 1em;
}

#ViewPark div#secction-6 > div:first-child div span {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: var(--color-white);
}

#ViewPark div#secction-6 > div:nth-child(2) {
    background-color: var(--color-grey);
    padding-top: 2em;
    padding-bottom: 2em;
}

#ViewPark div#secction-6 > div:nth-child(2) > div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

#ViewPark div#secction-6 > div:nth-child(2) > div:last-child {
    justify-content: flex-end;
    margin-top: 1em;
}

#ViewPark div#secction-6 > div:nth-child(2) > div div {
    background-color: var(--color-orange);
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

#ViewPark div#secction-6 > div:nth-child(2) > div:last-child div {
    background-color: #47573d;
}

#ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
    border: 2px solid var(--color-yellow);
    border-radius: 7px;
    height: 40px;
    color: var(--color-white);
    font-weight: bold;
    text-transform: uppercase;
}

#ViewPark div#secction-6 a {
    color: #ffffff;
}

#ViewPark div#secction-6 > div:nth-child(3) {
    width: 100%;
    text-align: center;
    padding: 1em 0.5em 1em 0.5em;
    font-weight: bold;
    font-size: 20px;
    color: #37640b;
    text-transform: uppercase;
}

#ViewPark div#secction-6 > div:nth-child(4) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    background-color: var(--color-grey);
}

#ViewPark div#secction-6 > div:nth-child(4) div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#ViewPark div#secction-6 > div:nth-child(4) div button {
    background-color: #6c8266;
    color: var(--color-white);
    border: none;
    border-radius: 10px;
    padding: 0.2em 0.5em 0.4em 0.5em;
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
}

#ViewPark div#secction-6 > div:nth-child(4) div iframe {
    width: 90%;
}

#ViewPark div#secction-7 > div img:first-child {
    width: 100px;
}

#ViewPark div#secction-7 {
    text-transform: uppercase;
}

#ViewPark div#secction-7 > div span {
    color: #d0d481;
    font-size: 18px;
    font-weight: 500;
}

#ViewPark div#secction-7 > div textarea {
    width: 90%;
    border: 1.5px solid #c1c6bd;
    outline: none;
    height: 150px;
}

#ViewPark div#secction-7 > div > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#ViewPark div#secction-7 > div > div div {
    width: 70%;
    font-weight: 500;
    color: #c4c9c0;
}

#ViewPark div#secction-7 > div > div img {
    width: 70px;
    cursor: pointer;
    margin-top: -0.5em;
}

#ViewPark div#secction-8 {
    background-color: #194d18;
    padding: 10px 20px;
    text-align: justify;
}

#ViewPark div#secction-8 h3 {
    color: #ffffff;
    font-size: 17px;
}

#ViewPark div#secction-9 {
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 100px;
}

#ViewPark div#secction-9 > h2 {
    text-align: center;
    color: #37640b;
    font-size: 2.5vw;
    font-weight: bold;
}

.no-results {
    width: 100%;
    height: 400px;
    display: grid;
    place-content: center;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.no-results img {
    width: auto;
}

#ViewPark .banner{
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    background-color: #F28F16;
    padding: 1% 0%;
}

@media (min-width: 384px) {
    #ViewPark div#secction-7 > div > div img {
        margin-top: -1em;
    }
}

@media (min-width: 360px) {
    #ViewPark div#secction-1 div span:last-child::before {
        width: 360px;
    }
}

@media (min-width: 400px) {
    #ViewPark div#secction-1 div span:last-child::before {
        width: 380px;
    }
}

@media (min-width: 500px) {
    #ViewPark div#secction-1 div span:first-child {
        margin-left: 5%;
    }

    #ViewPark div#secction-1 div span:last-child {
        margin-right: 5%;
    }

    #ViewPark div#secction-4 img {
        width: 50%;
    }

    #ViewPark div#secction-2 {
        justify-content: space-around;
    }

    /* #ViewPark div#secction-2 img {
        width: 250px;
    } */

    #ViewPark div#secction-4 div {
        width: 250px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 15px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        font-size: 15px;
    }
}

@media (min-width: 550px) {
    #ViewPark div#opportunities div.sub-sec5-2 div {
        width: 48%;
        margin-top: 0;
        margin-bottom: 10px;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div div {
        width: 80%;
    }

    #ViewPark div#secction-3 .body-card img {
        height: 400px;
    }

    #ViewPark div#secction-3 .sub-card-left {
        margin-left: 6em;
    }

    #ViewPark div#secction-3 .sub-card-right {
        margin-right: 5em;
    }
}

@media (min-width: 600px) {
    #ViewPark div#secction-2 div {
        width: 50%;
    }

    #ViewPark div#secction-6 > div:nth-child(4) div {
        width: 50%;
        margin-bottom: 2em;
    }

    #ViewPark div#secction-6 > div:nth-child(4) div img {
        width: 90%;
    }

    #ViewPark div#secction-3 .sub-card-left {
        margin-left: 10em;
    }

    #ViewPark div#secction-3 .sub-card-right {
        margin-right: 10em;
    }
}

@media (min-width: 700px) {
    #ViewPark iframe {
        height: 400px;
    }

    #ViewPark div#secction-3 .sub-card-left {
        margin-left: 3em;
    }

    #ViewPark div#secction-3 .sub-card-right {
        margin-right: 3em;
    }

    #ViewPark div#secction-3 .image-card {
        width: 50%;
        height: 300px;
    }
    #ViewPark div#secction-3 .body-card {
        width: 50%;
    }

    #ViewPark div#secction-3 .order-reverse .body-card {
        order: 2;
    }

    #ViewPark div#secction-3 .order-reverse .image-card {
        order: 1;
    }

    #ViewPark div#secction-1 div span {
        font-size: 20px;
    }

    #ViewPark div#secction-1 div span:first-child::before {
        width: 320px;
    }

    #ViewPark div#secction-1 div span:last-child::before {
        width: 430px;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div div {
        width: 60%;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        width: 85%;
    }

    #ViewPark div#secction-7 > div textarea {
        height: 200px;
    }

    #ViewPark div#secction-7 img:first-child {
        width: 140px !important;
        margin-bottom: -3em;
    }

    #ViewPark div#secction-7 > div span {
        font-size: 20px;
    }

    #ViewPark div#secction-7 > div > div img {
        margin-top: -1.9em;
    }
}

@media (min-width: 900px) {
    #ViewPark div#secction-7 > div textarea {
        height: 300px;
    }

    #ViewPark div#secction-7 > div span {
        font-size: 22px;
    }

    #ViewPark div#secction-7 > div > div {
        font-size: 17px;
    }

    #ViewPark div#secction-7 > div > div img {
        margin-top: -1.5em;
    }

    #ViewPark div#secction-4 img {
        height: 350px;
    }

    #ViewPark div#secction-4 div {
        font-size: 22px;
        width: 300px;
    }

    #ViewPark div#secction-6 > div:nth-child(4) div {
        width: 40%;
        margin-bottom: 2em;
    }

    #ViewPark div#secction-1 div span {
        font-size: 25px;
    }

    #ViewPark div#secction-1 div span:first-child::before {
        width: 380px;
    }

    #ViewPark div#secction-1 div span:last-child::before {
        width: 520px;
    }

    #ViewPark .header-park {
        height: 300px;
    }

    #ViewPark .header-park div h1 {
        font-size: 70px;
    }

    #ViewPark .header-park div p {
        font-size: 20px;
        margin-top: 0;
    }

    #ViewPark div#opportunities div.sub-sec5-1 span,
    #ViewPark div#secction-6 > div:nth-child(3) {
        font-size: 25px;
    }

    #ViewPark div#secction-6 > div:first-child span {
        font-size: 23px;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        width: 85%;
    }

    /* #ViewPark div#secction-2 img {
        width: 300px;
    } */

    #ViewPark div#secction-8 {
        padding: 10px 30px;
    }

    #ViewPark div#secction-8 h3 {
        font-size: 23px;
    }
}

@media (min-width: 1000px) {
    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        font-size: 15px;
    }
}

@media (min-width: 1100px) {
    #ViewPark div#opportunities div.sub-sec5-2 div {
        width: 23%;
        margin-top: 0;
        margin-bottom: 10px;
    }

    #ViewPark div#secction-3 .body-card,
    #ViewPark div#secction-3 .body-card button {
        font-size: 20px;
    }

    #ViewPark div#secction-3 .text-header-card {
        width: 300px;
    }

    #ViewPark div#secction-3 .text-header-card::before {
        width: 350px;
    }

    #ViewPark div#secction-3 .body-card button {
        height: 50px;
    }

    #ViewPark div#secction-3 .sub-card-left {
        margin-left: 4em;
    }

    #ViewPark div#secction-3 .sub-card-right {
        margin-right: 4em;
    }

    #ViewPark div#secction-3 .body-card,
    #ViewPark div#secction-3 .image-card {
        height: 400px;
    }

    #ViewPark div#secction-3 .body-card > div img {
        width: 80px;
        height: 80px;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        width: 80%;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        font-size: 17px;
    }

    /* #ViewPark div#secction-2 img {
        width: 350px;
    } */

    #ViewPark div#secction-3 > div,
    #ViewPark div#secction-3 img {
        height: 400px;
    }

    #ViewPark div#secction-3 > div > div > img {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 1200px) {
    #ViewPark div#secction-6 > div:nth-child(4) div {
        width: 35%;
        margin-bottom: 2em;
    }

    #ViewPark iframe {
        height: 600px;
    }

    /* #ViewPark div#secction-2 img {
        width: 400px;
    } */

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        font-size: 18px;
    }

    #ViewPark div#secction-7 > div span {
        font-size: 25px;
    }

    #ViewPark div#secction-3 .body-card #ViewPark div#secction-3 .image-card {
        height: 450px;
    }
}

@media (min-width: 1300px) {
    #ViewPark div#secction-8 h3 {
        text-align: center;
    }
}

@media (min-width: 1500px) {
    #ViewPark div#secction-1 div span,
    #ViewPark div#opportunities div.sub-sec5-1 span,
    #ViewPark div#secction-6 > div:nth-child(3),
    #ViewPark div#secction-4 div,
    #ViewPark div#secction-6 > div:first-child span {
        font-size: 30px;
    }

    #ViewPark div#secction-1 div span:first-child {
        margin-left: 5em;
    }

    #ViewPark div#secction-1 div span:first-child::before {
        width: 460px;
    }

    #ViewPark div#secction-1 div span:last-child {
        margin-right: 5em;
    }

    #ViewPark div#secction-1 div span:last-child::before {
        width: 640px;
    }

    #ViewPark div#secction-3 .text-header-card {
        width: 400px;
    }

    #ViewPark div#secction-3 .body-card,
    #ViewPark div#secction-3 .body-card > img,
    #ViewPark div#secction-3 .image-card,
    #ViewPark div#secction-4 img {
        height: 600px;
    }

    #ViewPark div#secction-3 .body-card,
    #ViewPark div#secction-3 .body-card button {
        font-size: 25px;
    }

    #ViewPark div#secction-3 .body-card button {
        height: 70px;
    }

    #ViewPark div#secction-3 .sub-card-left {
        margin-left: 6em;
    }

    #ViewPark div#secction-3 .sub-card-right {
        margin-right: 6em;
    }

    #ViewPark div#secction-3 .body-card > div img {
        width: 110px;
        height: 110px;
    }

    #ViewPark iframe {
        height: 800px;
    }

    #ViewPark div#secction-4 div {
        width: 400px;
    }

    #ViewPark div#secction-6 > div:nth-child(2) > div > div > div {
        font-size: 20px;
    }

    #ViewPark div#opportunities div.sub-sec5-2 div div {
        font-size: 23px;
    }

    #ViewPark div#secction-6 > div:nth-child(4) div button {
        font-size: 25px;
    }

    #ViewPark .header-park div h1 {
        font-size: 90px;
    }

    #ViewPark .header-park div p {
        font-size: 25px;
    }

    /* #ViewPark div#secction-2 img {
        width: 450px;
    } */

    #ViewPark div#secction-7 > div textarea {
        width: 75%;
        height: 350px;
    }

    #ViewPark div#secction-7 img:first-child {
        width: 140px !important;
        margin-bottom: -3em;
    }

    #ViewPark div#secction-7 > div span {
        font-size: 30px;
    }

    #ViewPark div#secction-7 > div > div {
        font-size: 20px;
    }

    #ViewPark div#secction-7 > div > div img {
        margin-top: -1em;
    }
}

.card-park .card .card-image .btn-floating {
    background-color: var(--color-dark-green);
}

.card-park .card .card-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.card-park .card .card-content {
    height: 150px;
    display: grid;
    place-items: center;
}
#CardService .card-services {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Estilos para el título */
#CardService .card-services .card-header {
    background-color: #F28F16;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
}

/* Estilos para el cuerpo */
#CardService .card-services .card-body {
    padding: 15px;
    text-align: justify;
}

/* Estilos para el pie de página */
#CardService .card-services .card-footer {
    background-color: #f5f5f5;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#CardService.container {
    display: flex;
    flex-direction: column;
}

/* Estilos para las columnas */
#CardService .column {
    text-align: center;
    margin-bottom: 20px;
    flex-basis: calc(50% - 20px);
}

/* Estilos para hacerlo responsivo */
@media (min-width: 768px) {
    #CardService.container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #CardService .column {
        flex-basis: calc(33.33% - 20px);
    }
}

#FormContact form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 3em;
    margin: 0 auto;
    width: 80%;
}

#FormContact form div {
    width: 100%;
    font-size: 18px;
    margin-bottom: 2em;
}

#FormContact form div label {
    font-size: 18px;
    font-weight: 500;
}

#FormContact form div input {
    border: 2px solid var(--color-grey);
    outline: none;
    box-shadow: none;
    padding-left: .5em;
    width: 96%;
}

#FormContact form div textarea {
    border: 2px solid var(--color-grey);
    outline: none;
    box-shadow: none;
    height: 200px;
}

#FormContact form input[type="submit"] {
    margin-bottom: 2em;
    border: none;
    background-color: var(--color-dark-green);
    font-size: 18px;
    padding: .5em 1.5em .5em 1.5em;
    color: var(--color-white);
    font-weight: 500;
    border-radius: 7px;
    cursor: pointer;
}

#FormContact form div#privacy-policy {
    color: rgba(0, 0, 0, 0.2);
    font-weight: 500;
    text-align: center;
}

#FormContact form div#privacy-policy a {
    color: rgba(0, 0, 0, 0.2);
}

@media (min-width: 450px) {
    #FormContact form div input {
        width: 97.5%;
    }
}

@media (min-width: 650px) {
    #FormContact form {
        width: 60%;
    }
}

@media (min-width: 900px) {
    #FormContact form {
        width: 40%;
    }
}

@media (min-width: 1250px) {
    #FormContact form div input {
        width: 98.5%;
    }
}

@media (min-width: 2000px) {
    #FormContact form {
        width: 30%;
    }
}

@media (min-width: 2750) {
    #FormContact form div input {
        width: 99%;
    }
}
#Services .container-fluid {
    background-image: url(/static/media/28.e8c06612.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    font-weight: 500;
    margin-top: -42px;
    height: 800px;
    position: relative;
    font-size: 15px;
}

#Services .container-fluid::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#Services .container-fluid h1 {
    font-size: 35px;
    margin-top: 1.5em;
}

#Services .container-fluid > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}

#Services .container-fluid > div div > ul {
    margin-left: 1.5em;
}

#Services .container-fluid > div div > ul > li {
    font-weight: 800;
}

#Services .container-fluid > div ul li {
    list-style: disc;
}

#Services .container-fluid > div ul li > ul {
    margin-left: 3em;
    font-weight: 400;
}

@media (min-width: 400px) {
    #Services .container-fluid {
        font-size: 16px;
        height: 800px;
    }

    #Services .container-fluid h1 {
        font-size: 40px;
    }
}

@media (min-width: 500px) {
    #Services .container-fluid {
       font-size: 18px;
    }

    #Services .container-fluid h1 {
        font-size: 50px;
        margin-top: 0;
    }
}

@media (min-width: 600px) {
    #Services .container-fluid {
       font-size: 19px;
    }

    #Services .container-fluid h1 {
        font-size: 60px;
    }

    #Services .container-fluid > div {
        width: 85%;
    }
}

@media (min-width: 700px) {
    #Services .container-fluid {
       font-size: 20px;
    }
}

@media (min-width: 900px) {
    #Services .container-fluid > div {
        width: 80%;
    }
}

@media (min-width: 1200px) {
    #Services .container-fluid > div {
        width: 60%;
    }
}

@media (min-width: 1720px) {
    #Services .container-fluid {
       font-size: 22px;
       height: 800px;
    }

    #Services .container-fluid h1 {
        font-size: 90px;
    }
}

@media (min-width: 2050px) {
    #Services .container-fluid {
       font-size: 27px;
       height: 900px;
    }

    #Services .container-fluid h1 {
        font-size: 95px;
    }
}

@media (min-width: 2500px) {
    #Services .container-fluid {
       font-size: 30px;
       height: 1000px;
    }

    #Services .container-fluid h1 {
        font-size: 110px;
    }
}

@media (min-width: 3000px) {
    #Services .container-fluid {
       font-size: 38px;
       height: 1200px;
    }

    #Services .container-fluid h1 {
        font-size: 120px;
    }
}
#ListParks > div.container {
    margin-top: 5em;
    min-height: 75vh;
}

#ListParks > div.container div.card div.card-image a.btn-floating {
    background-color: var(--color-dark-green);
}

#ListParks > div.container div.card div.card-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

#ListParks > div.container div.card div.card-content {
    height: 150px;
    display: grid;
    place-items: center;
}

#ListParks > div.container div.row div.no-results {
    width: 100%;
    height: 75vh;
    display: grid;
    place-content: center;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

#ListParks > div.container div.row div.no-results img {
    width: 150px;
}
