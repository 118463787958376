#Services .container-fluid {
    background-image: url('../../Images/28.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    font-weight: 500;
    margin-top: -42px;
    height: 800px;
    position: relative;
    font-size: 15px;
}

#Services .container-fluid::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#Services .container-fluid h1 {
    font-size: 35px;
    margin-top: 1.5em;
}

#Services .container-fluid > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}

#Services .container-fluid > div div > ul {
    margin-left: 1.5em;
}

#Services .container-fluid > div div > ul > li {
    font-weight: 800;
}

#Services .container-fluid > div ul li {
    list-style: disc;
}

#Services .container-fluid > div ul li > ul {
    margin-left: 3em;
    font-weight: 400;
}

@media (min-width: 400px) {
    #Services .container-fluid {
        font-size: 16px;
        height: 800px;
    }

    #Services .container-fluid h1 {
        font-size: 40px;
    }
}

@media (min-width: 500px) {
    #Services .container-fluid {
       font-size: 18px;
    }

    #Services .container-fluid h1 {
        font-size: 50px;
        margin-top: 0;
    }
}

@media (min-width: 600px) {
    #Services .container-fluid {
       font-size: 19px;
    }

    #Services .container-fluid h1 {
        font-size: 60px;
    }

    #Services .container-fluid > div {
        width: 85%;
    }
}

@media (min-width: 700px) {
    #Services .container-fluid {
       font-size: 20px;
    }
}

@media (min-width: 900px) {
    #Services .container-fluid > div {
        width: 80%;
    }
}

@media (min-width: 1200px) {
    #Services .container-fluid > div {
        width: 60%;
    }
}

@media (min-width: 1720px) {
    #Services .container-fluid {
       font-size: 22px;
       height: 800px;
    }

    #Services .container-fluid h1 {
        font-size: 90px;
    }
}

@media (min-width: 2050px) {
    #Services .container-fluid {
       font-size: 27px;
       height: 900px;
    }

    #Services .container-fluid h1 {
        font-size: 95px;
    }
}

@media (min-width: 2500px) {
    #Services .container-fluid {
       font-size: 30px;
       height: 1000px;
    }

    #Services .container-fluid h1 {
        font-size: 110px;
    }
}

@media (min-width: 3000px) {
    #Services .container-fluid {
       font-size: 38px;
       height: 1200px;
    }

    #Services .container-fluid h1 {
        font-size: 120px;
    }
}