:root {
  --color-white: #fff;
  --color-black: #000;
  --color-dark-green: #194d18;
  --color-medium-green: #4f5e45;
  --color-light-green: #3bae29;
  --color-orange: #e4942d;
  --color-yellow: #ffd83c;
  --color-crimson: #4e180c;
  --color-grey: #eaeceb;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

.rec-arrow {
  display: none !important;
}

.rec-slider-container {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.rec-dot:hover,
.rec-dot:focus {
  box-shadow: 0 0 1px 3px var(--color-crimson) !important;
}

.rec-dot_active {
  background: var(--color-crimson) !important;
  box-shadow: 0 0 1px 3px var(--color-crimson) !important;
}

.custom-carousel-item,
.custom-carousel-item img {
  width: 100vw;
}

iframe {
  width: 100%;
  height: 90vh;
  margin: 25px 0;
}