#ListParks > div.container {
    margin-top: 5em;
    min-height: 75vh;
}

#ListParks > div.container div.card div.card-image a.btn-floating {
    background-color: var(--color-dark-green);
}

#ListParks > div.container div.card div.card-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

#ListParks > div.container div.card div.card-content {
    height: 150px;
    display: grid;
    place-items: center;
}

#ListParks > div.container div.row div.no-results {
    width: 100%;
    height: 75vh;
    display: grid;
    place-content: center;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

#ListParks > div.container div.row div.no-results img {
    width: 150px;
}