nav {
    background: var(--color-dark-green) !important;
    box-shadow: none !important;
    align-content: center;
}

div.form-group div input[placeholder="Search"] {
    height: 61px;
    box-shadow: none;
    padding-left: 1em;
    border: 1.5px solid var(--color-grey);
    width: 93%;
}

div ul#slide-out li a[href="#modalSearch"] {
    position: relative;
}

div ul#slide-out li a[href="#modalSearch"] i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="submit"] {
    background-color: var(--color-dark-green);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
    padding: .5em 1em .5em 1em;
    font-size: 20px;
    cursor: pointer;
}

#modalSearch button#btn-close-modal {
    position:absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: var(--color-white);
    border-bottom-left-radius: 25px;
}

nav .brand-logo img {
    width: 80px;
}

nav ul li a {
    max-height: 62px;
    font-size: 20px;
}

nav .line-white-vertical {
    position: relative;
}

nav .line-white-vertical::after {
    position: absolute;
    content: '';
    right: -10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 3px;
    height: 40px;
    background-color: white;
}

nav ul li a img {
    width: 45px;
    height: 45px;
    object-fit: contain;
    margin-top: 10px;
}

ul.sidenav li {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #f1f1f1;
    padding-top: 1em;
    padding-bottom: 1em;
}

ul.sidenav li a img {
    width: 45px;
    height: 45px;
    object-fit: contain;
}

@media (min-width: 400px) {
    div.form-group div input[placeholder="Search"] {
        width: 94%;
    }
}

@media (min-width: 400px) {
    div.form-group div input[placeholder="Search"] {
        width: 94%;
    }
}

@media (min-width: 992px) {
    div.form-group div input[placeholder="Search"] {
        height: 64px;
    }
}

@media (min-width: 1700px) {
    nav ul li a {
        font-size: 25px;
    }
}
