div#Footer footer {
    position: relative;
    background-color: var(--color-dark-green);
    font-size: 20px;
    z-index: 1;
}

div#Footer footer::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../Images/17.jpg');
    background-size: cover;
    background-position: 100%;
    opacity: 15%;
    z-index: -1;
}

div#Footer footer a {
    color: white;
}

div#Footer footer img.logo {
    width: 250px;
    object-fit: contain;
}

div#Footer footer div.container div.row div.col:first-child {
    display: flex;
    justify-content: center;
}

div#Footer footer div.container div.row div.col:last-child > div {
    text-align: center;
    margin-top: 2em;
}

@media (min-width: 992px) {
    div#Footer footer div.container div.row div.col:last-child > div {
        text-align: left;
        margin-top: 0;
    }
}