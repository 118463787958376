#Landing div.custom-carousel-container {
    margin: 0 0 100px 0;
}

#Landing div.custom-carousel-container div.carousel-container {
    position: relative;
}

#Landing
    div.custom-carousel-container
    div.carousel-container
    .custom-carousel-item {
    position: relative;
}

#Landing
    div.custom-carousel-container
    div.carousel-container
    .custom-carousel-item::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url('../../Images/text-banner-android.png');
    background-size: 100% 98%;
    background-repeat: no-repeat;
}

#Landing
    div.custom-carousel-container
    div.carousel-container
    .custom-carousel-item-first::before {
    background-image: url('../../Images/text-banner-android-icon.png') !important;
}

#Landing div.row {
    margin: 0;
}

#Landing div.col {
    padding: 0;
}

#Landing div.collage {
    margin-top: -10em;
}

#Landing div.collage div.top {
    background: var(--color-medium-green);
}

#Landing div.collage div.top div img {
    width: 100%;
}

#Landing div.collage div.top div:last-child {
    display: flex;
    justify-content: center;
    align-content: center;
}

#Landing div.collage div.top div span {
    display: block;
    color: white;
    width: 80%;
    text-align: center;
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1em;
}

#Landing div.collage div.bottom {
    background: var(--color-orange);
}

#Landing div.collage div.bottom div.orange-top-bar {
    height: 3em;
}

#Landing div.collage div.bottom img {
    width: 100%;
}

#Landing div.show-desk,
#Landing div.show-desk-2 {
    display: none !important;
}

#Landing div.collage div.bottom div.bottom-text,
#Landing div.collage div.bottom div.top-text {
    display: flex;
    justify-content: center;
}

#Landing
    div.collage
    div.bottom
    div.left-portion
    div.bottom-text
    div.box-behind-text,
#Landing
    div.collage
    div.bottom
    div.right-portion
    div.bottom-text
    div.box-behind-text {
    width: 250px;
    height: 80px;
    border: 3px solid var(--color-white);
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em;
}

#Landing
    div.collage
    div.bottom
    div.left-portion
    div.bottom-text
    div.box-behind-text
    span.bordered-text,
#Landing
    div.collage
    div.bottom
    div.right-portion
    div.bottom-text
    div.box-behind-text
    span.bordered-text {
    color: var(--color-crimson);
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translate(0, -50%);
    width: 280px;
    background: var(--color-orange);
    text-align: center;
}

#Landing div.collage div.bottom div.center-portion {
    background: var(--color-grey);
}

#Landing div.collage div.bottom div.center-portion img {
    margin-top: 3em;
}

#Landing
    div.collage
    div.bottom
    div.center-portion
    div.top-text
    div.box-behind-text {
    width: 250px;
    height: 80px;
    border: 3px solid var(--color-light-green);
    position: relative;
    margin-top: 2em;
    margin-bottom: 2em;
}

#Landing
    div.collage
    div.bottom
    div.center-portion
    div.top-text
    div.box-behind-text
    span.bordered-text {
    color: var(--color-medium-green);
    background: var(--color-grey);
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translate(0, -50%);
    width: 280px;
    text-align: center;
}

#Landing div.secction-1 > div:last-child div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

#Landing div.secction-1 > div:first-child,
#Landing div.secction-2 div.col > div.row:first-child {
    text-align: center;
    padding: 2em 0.5em 2em 0.5em;
}

#Landing div.secction-1 > div:first-child span,
#Landing div.secction-2 div.col > div.row:first-child span {
    color: var(--color-medium-green);
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
}

#Landing div.secction-1 > div:last-child {
    background-color: var(--color-medium-green);
    text-align: center;
}

#Landing div.secction-1 > div:last-child div span {
    color: var(--color-white);
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

#Landing div.secction-1 > div:last-child div img {
    width: 100%;
}

#Landing div.secction-2 div.col > div.row:last-child {
    background-image: url('../../Images/background-secction-image.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

#Landing div.secction-2 div.col > div.row:last-child div {
    padding: 0 0.5em 0 0.5em;
    text-align: center;
    margin: 1em 0 1em 0;
}

#Landing div.secction-2 div.col > div.row:last-child span {
    color: var(--color-white);
    font-weight: 500;
    font-size: 20px;
}

#Landing div.secction-2 div.col > div.row:last-child p {
    color: var(--color-white);
    font-size: 15px;
    line-height: 18px;
}

#Landing div.secction-3 div.row div.col {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

#Landing div.secction-3 div.row div.col img {
    width: 50%;
    height: 200px;
    object-fit: cover;
}

.map-landing .button-map-landing {
    background-color: #1785fb !important;
}

.close {
    display: none;
}

.map-landing .blok-map-landing {
    height: 95% !important;
}
.informative_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.informative_section h2 {
    font-size: 25px;
    text-align: center;
}
.informative_section ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.informative_section ul li {
    font-size: 20px;
    text-align: center;
    padding: 20px 0px;
}
@media (min-width: 500px) {
    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item::before {
        background-image: url('../../Images/text-banner.png');
        background-size: 100% 99%;
    }

    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item-first::before {
        background-image: url('../../Images/text-banner-icon.png') !important;
    }

    #Landing div.secction-1 > div:last-child div img {
        width: 80%;
    }

    #Landing div.secction-2 div.col > div.row:last-child div:nth-child(2n - 1) {
        text-align: left;
    }

    #Landing div.secction-2 div.col > div.row:last-child div:nth-child(2) {
        text-align: right;
    }

    #Landing div.secction-2 div.col > div.row:last-child {
        padding: 0 4em 0 4em;
    }
}

@media (min-width: 600px) {
    #Landing div.secction-1 > div:last-child div.col:first-child {
        display: flex;
        justify-content: flex-end;
    }

    #Landing div.secction-1 > div:last-child div.col:last-child {
        display: flex;
        justify-content: flex-start;
    }

    #Landing div.show-desk {
        display: flex !important;
    }

    #Landing div.show-android {
        display: none !important;
    }

    #Landing div.secction-1 > div:first-child {
        padding-bottom: 5em;
    }

    #Landing div.secction-1 > div:last-child div.row:first-child {
        margin-top: -8%;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(3) {
        margin-top: -12%;
        margin-bottom: -12%;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(4) {
        margin-bottom: -10%;
    }

    #Landing div.secction-2 div.row:first-child {
        margin-top: 2em;
    }

    #Landing div.secction-3 div.row div.col img {
        width: 45%;
        height: 300px;
    }
}

@media (min-width: 760px) {
    .map-landing .button-map-landing {
        top: 22px;
    }
}

@media (min-width: 992px) {
    #Landing div.show-desk-2 {
        display: flex !important;
    }

    #Landing .show-tablet {
        display: none !important;
    }

    #Landing div.collage div.top {
        display: flex;
        align-items: center;
    }

    #Landing div.collage div.top div span {
        font-size: 25px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text {
        width: 300px;
        height: 100px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text
        span.bordered-text {
        font-size: 20px;
        width: 330px;
        padding-left: 15px;
        padding-right: 15px;
    }

    #Landing div.collage div.bottom {
        margin-top: -5px;
    }

    #Landing div.collage div.bottom div.center-portion img {
        margin-top: 0;
        margin-bottom: 3em;
    }

    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item::before {
        background-size: 100% 99.6%;
    }

    #Landing div.secction-1 > div:first-child span,
    #Landing div.secction-2 div.col > div.row:first-child span {
        font-size: 35px;
    }

    #Landing div.secction-1 > div:last-child div span {
        font-size: 25px;
    }

    #Landing div.secction-2 div.col > div.row:last-child span {
        font-size: 30px;
    }

    #Landing div.secction-2 div.col > div.row:last-child p {
        font-size: 20px;
        line-height: 30px;
    }

    #Landing div.secction-1 > div:last-child div img {
        width: 450px;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(3) {
        margin-top: -10%;
        margin-bottom: -10%;
    }

    #Landing div.secction-3 div.row div.col img {
        height: 400px;
    }

    .informative_section h2 {
        font-size: 35px;
    }

    .informative_section ul li {
        font-size: 30px;
        padding: 20px 0px;
    }
}

@media (min-width: 1500px) {
    #Landing div.secction-1 > div:last-child {
        padding-top: 1%;
        padding-bottom: 2%;
    }

    #Landing div.secction-1 > div:last-child div.row:nth-child(3) {
        margin-top: -8%;
        margin-bottom: -8%;
    }

    #Landing div.secction-3 div.row div.col img {
        width: 45%;
        height: 500px;
    }
}

@media (min-width: 1700px) {
    #Landing div.secction-1 > div:last-child {
        padding-top: 3%;
        padding-bottom: 4%;
    }

    #Landing div.collage div.top div span {
        font-size: 30px;
    }

    #Landing div.secction-2 div.col > div.row:last-child span {
        font-size: 35px;
    }

    #Landing div.secction-2 div.col > div.row:last-child p {
        font-size: 25px;
    }

    #Landing div.secction-1 > div:first-child span,
    #Landing div.secction-2 div.col > div.row:first-child span {
        font-size: 40px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text {
        width: 320px;
        height: 110px;
    }

    #Landing
        div.collage
        div.bottom
        div.left-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.right-portion
        div.bottom-text
        div.box-behind-text
        span.bordered-text,
    #Landing
        div.collage
        div.bottom
        div.center-portion
        div.top-text
        div.box-behind-text
        span.bordered-text {
        font-size: 25px;
        width: 350px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #Landing div.secction-3 div.row div.col img {
        height: 600px;
    }
}

@media (min-width: 2200px) {
    #Landing
        div.custom-carousel-container
        div.carousel-container
        .custom-carousel-item::before {
        background-size: 100% 99.7%;
    }

    #Landing div.secction-3 div.row div.col img {
        height: 750px;
    }
}
